// import * as firebase from 'firebase/app'
// import 'firebase/firestore'

// async function x() {
//   var promise = new Promise(function(resolve, reject) {
//     window.setTimeout(function() {
//       const report = []
//       firebase.firestore().collection('reports').doc('leder').collection('dashboards').get()
//         .then(snapshot => {
//           const base = '/report/'
//           snapshot.forEach(doc => {
//             report.push({
//               title: doc.data().title,
//               key: doc.data().id,
//               url: base.concat(doc.data().id),
//             })
//           })
//         })
//       console.log(report)
//       resolve(report)
//     })
//   })
//   return promise
// }

export const getMenuData = [
  {
    title: 'Hjem',
    key: 'dashboard',
    icon: 'fe fe-home',
    // roles: ['admin'], // set user roles with access to this route
    url: '/dashboard/news',
  },
  {
    title: 'Kontakter',
    key: 'contacts',
    icon: 'fa fa-address-book',
    url: '/hr/contacts',
    roles: ['HRseeUsersDepartment', 'HRseeUsersAll'],
  },
  {
    title: 'Rapporter',
    key: 'reports2',
    icon: 'fe fe-pie-chart',
    url: '/reports',
    roles: ['reports'],
  },
  {
    title: 'Tabeller',
    key: 'tables',
    url: '/tools/supporttables',
    icon: 'fa fa-table',
    roles: ['supporttables'],
  },
  // {
  //   title: 'Godkjenning',
  //   key: 'approval',
  //   icon: 'fa fa-check-circle',
  //   roles: ['underDevelopment'],
  //   url: '/approval',
  // },
  {
    title: 'Oppgaver',
    key: 'tasks',
    icon: 'fa fa-briefcase',
    url: '/tasks',
    roles: ['tasks'],
  },
  {
    title: 'Signering',
    key: 'sign',
    icon: 'fa fa-pencil-square-o',
    url: '/sign',
    roles: ['signature'],
  },
  // {
  //   title: 'Min Lønn',
  //   key: 'salary',
  //   icon: 'fa fa-money',
  //   roles: ['underDevelopment'],
  //   url: '/payroll',
  // },
  {
    title: 'HR',
    key: 'HR2',
    icon: 'fe fe-users',
    roles: ['HRseeUsersDepartment', 'HRseeUsersAll', 'HRcreateUsersAll', 'HRcreateUsersDepartment'], // set user roles with access to this route
    children: [
      {
        title: 'Ansatte',
        key: 'HR-persons',
        url: '/hr/persons/active',
        roles: ['HRseeUsersDepartment', 'HRseeUsersAll', 'HRseeUsersProject', 'HRseeUsersProjectDepartment'],
      },
      {
        title: 'Inaktive',
        key: 'HR-inactive',
        url: '/hr/persons/inactive',
        roles: ['HRseePreviousEmployment'],
      },
      {
        title: 'Under Onboarding',
        key: 'HR-underOnboarding',
        url: '/hr/persons/onboarding',
        roles: ['HRseeUsersDepartment', 'HRseeUsersAll', 'HRseeUsersProject', 'HRseeUsersProjectDepartment'],
      },
      {
        title: 'Ny ansatt',
        key: 'HR-oboarding2',
        url: '/hr/newOnboarding',
        roles: ['HRcreateUsersAll', 'HRcreateUsersDepartment'],
      },
      {
        title: 'HR Instillinger',
        key: 'HR-settings',
        url: '/hr/settings',
        roles: ['HRsettings'],
      },
    ],
  },
  {
    title: 'Bursdager',
    key: 'birthdays',
    icon: 'fa fa-calendar',
    url: '/hr/birthdays',
  },
  {
    title: 'Min Lønn',
    key: 'MyPayslips',
    url: '/payroll/my-payslips',
    icon: 'fa fa-money',
    roles: ['underDevelopment'],
  },
  {
    title: 'Timeliste',
    key: 'hoursList',
    icon: 'fa fa-clock-o',
    roles: ['HRseeUsersAll', 'HRseeUsersDepartment', 'HRseeUsersProject', 'HRseeUsersProjectDepartment'],
    url: '/timesheet/manager',
  },
  {
    title: 'Lønn',
    key: 'salary',
    icon: 'fa fa-money',
    roles: ['underDevelopment'],
    children: [
      {
        title: 'Lønnskjøringer',
        key: 'Payroll',
        url: '/payroll/payrolls',
        roles: ['underDevelopment'],
      },
      {
        title: 'Lønnsslipper',
        key: 'Payslips',
        url: '/payroll/payslips',
        roles: ['underDevelopment'],
      },
      {
        title: 'Transaksjoner',
        key: 'payrollTransactions',
        url: '/payroll/transactions',
        roles: ['underDevelopment'],
      },
      {
        title: 'Konsulenter',
        key: 'payrollConsultants',
        url: '/payroll/consultants',
        roles: ['underDevelopment'],
      },
      {
        title: 'Bonus',
        key: 'payrollBonus',
        url: '/payroll/bonus',
        roles: ['underDevelopment'],
      },
      {
        title: 'Rekruttering',
        key: 'payrollRecruitment',
        url: '/payroll/recruitment',
        roles: ['underDevelopment'],
      },
      {
        title: 'Sykdom',
        key: 'payrollSickleave',
        url: '/payroll/sickleave',
        roles: ['underDevelopment'],
      },
      {
        title: 'Garantilønn',
        key: 'pauyrollGuaranteedSalary',
        url: '/payroll/guaranteedSalary',
        roles: ['underDevelopment'],
      },
      {
        title: 'Import',
        key: 'payrollImport',
        url: '/payroll/import',
        roles: ['underDevelopment'],
      },
      {
        title: 'Instillinger',
        key: 'payrollSettings',
        url: '/payroll/settings',
        roles: ['underDevelopment'],
      },
    ],
  },
  {
    title: 'Talkmore Privat',
    key: 'TalkmoreB2C',
    icon: 'fe fe-phone',
    roles: ['talkmoreB2Csell', 'talkmoreB2Csettings', 'talkmoreB2Cedit'], // set user roles with access to this route
    children: [
      {
        title: 'Rapport',
        key: 'tmb2c-report',
        url: '/talkmoreB2C/report',
        roles: ['talkmoreB2Csell', 'TalkmoreB2CReport', 'TalkmoreB2CReportManager'],
      },
      {
        title: 'Infoweb',
        key: 'tmb2c-infoweb',
        url: '/talkmoreB2C/infoweb',
        roles: ['talkmoreB2Csell'],
      },
      {
        title: 'Ny Salg',
        key: 'tmb2c-new-sale',
        url: '/talkmoreB2C/newSale',
        roles: ['talkmoreB2Csell', 'talkmoreB2Cedit'],
      },
      {
        title: 'Salgsoversikt',
        key: 'tmb2c-sales',
        url: '/talkmoreB2C/sales',
        roles: ['talkmoreB2Csell', 'talkmoreB2Cedit'],
      },
      {
        title: 'Produkter',
        key: 'tmb2c-products',
        url: '/talkmoreB2C/products',
        roles: ['talkmoreB2Csell', 'talkmoreB2Cedit'],
      },
      {
        title: 'EP Brukere',
        key: 'tmb2c-epusers',
        url: '/talkmoreB2C/epusers',
        roles: ['talkmoreB2Csettings', 'talkmoreB2Cep'],
      },
      {
        title: 'Instillinger',
        key: 'tmb2c-settings',
        url: '/talkmoreB2C/settings',
        roles: ['talkmoreB2Csettings'],
      },
    ],
  },
  {
    title: 'Verktøy',
    key: 'tools',
    icon: 'fa fa-briefcase',
    children: [
      {
        title: 'Kvalitetsjekk',
        key: 'quality-control',
        url: '/tools/qualityControl',
        roles: ['underDevelopment'],
      },
      {
        title: 'Carrier Lookup',
        key: 'carrier-lookup',
        url: '/tools/carrierlookup',
        roles: ['carrierLookup'],
      },
      {
        title: 'Heic til jpg',
        key: 'heic-converter',
        url: '/tools/heicconverter',
      },
      {
        title: 'E-post signatur',
        key: 'email-signature',
        url: '/tools/signatureGenerator',
      },
    ],
  },
  // {
  //   title: 'HR',
  //   key: 'HR',
  //   icon: 'fe fe-users',
  //   roles: ['admin', 'hr', 'trondheim', 'kristiansand'], // set user roles with access to this route
  //   children: [
  //     {
  //       title: 'Onboarding SkyCall',
  //       key: 'HR-skycall',
  //       url: '/hr/skycall',
  //     },
  //     {
  //       title: 'Onboarding Sky Finance',
  //       key: 'HR-skyfinance',
  //       url: '/hr/skyfinance',
  //     },
  //   ],
  // },
  {
    title: 'CMS',
    key: 'cms',
    icon: 'fas fa-rss',
    roles: ['cms'],
    url: '/cms/posts',
  },
  {
    title: 'Personalhåndbok',
    icon: 'fe fe-layers',
    url: '/employeehandbook',
  },
  {
    title: 'Dokumenter',
    key: 'documents',
    url: '/documents',
    icon: 'fe fe-archive',
  },
  {
    title: 'Profil',
    key: 'appsProfile',
    icon: 'fe fe-user',
    url: '/profile',
  },
  {
    title: 'Support (Beta)',
    key: 'support',
    icon: 'fa fa-support',
    url: '/helpdesk/chat',
    roles: ['helpdeskDevelopment'],
  },
  {
    title: 'Personlige instillinger',
    key: 'my-settings',
    icon: 'fe fe-settings',
    url: '/my-settings',
  },
  {
    title: 'Instillinger',
    key: 'settings-main',
    icon: 'fe fe-settings',
    roles: ['CompanySettings', 'TVSettingsAll', 'TVSettingsDep'],
    children: [
      {
        title: 'Selskap Instillinger',
        key: 'settings',
        icon: 'fe fe-settings',
        url: '/settings',
        roles: ['CompanySettings'],
      },
      {
        title: 'TV instillinger',
        key: 'tv-settings',
        icon: 'fa fa-tv',
        url: '/tv/settings',
        roles: ['TVSettingsAll', 'TVSettingsDep'],
      },
      {
        title: 'Integrasjoner',
        key: 'integration-settings',
        icon: 'fe fe-settings',
        url: '/settings/integration',
        roles: ['underDevelopment'],
      },
    ],
  },
  {
    category: true,
    title: 'Apps',
  },
  {
    title: 'Office',
    icon: 'fa fa-envelope',
    url: '/office365',
  },
]
